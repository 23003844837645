<template>
  <div class="booking-form">
    <div class="booking-form-left">
      <div class="booking-form-left-header">
        <h2>{{ booking.spot.name }}</h2>
      </div>

      <!-- gallery -->
      <div class="booking-form-left-gallery">
        <div class="main">
          <el-image :src="preview" :fit="'cover'">
            <div slot="placeholder" class="image-slot">
              {{ $t('COMMON.ONGOING_LOADING') }}
            </div>
            <div slot="error" class="el-image__error">
              <i class="el-icon-picture-outline" style="font-size: 2em"></i>
            </div>
          </el-image>
        </div>
        <div class="list">
          <ul>
            <li @click="() => (preview = booking.spot.picture)">

              <el-image :src="booking.spot.picture" :fit="'cover'">
                <div slot="error" class="el-image__error">
                  <i class="el-icon-picture-outline" style="font-size: 2em"></i>
                </div>
              </el-image>
            </li>
            <li
              v-for="img in booking.spot.gallery"
              :key="img"
              @click="() => (preview = img)"
            >
              <el-image :src="img" :fit="'cover'">
                <div slot="error" class="el-image__error" style="font-size: 2em">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </li>
          </ul>
        </div>
      </div>

      <div class="booking-form-left-details">
        <div class="details">
          <h2>{{ $t("COMMON.DETAILS") }}</h2>
          <div class="details-items">
            <div class="details-items-item">
              <ul>
                <li>Nom de l'emplacement</li>
                <li>{{ booking.spot.name }}</li>
              </ul>
            </div>
            <div class="details-items-item">
              <ul>
                <li>Type d'emplacement</li>
                <li>{{ booking.spot.spotType?.name }}</li>
              </ul>
            </div>
            <div class="details-items-item">
              <ul>
                <li>Dimension de l'emplacement</li>
                <li>
                  {{ booking.spot.equipment_length }}
                  {{ $t("COMMON.UNIT_FEET") }}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- description -->
        <div class="description">
          <h4>{{ $t("COMMON.EXCERPT") }}</h4>
          <p v-html="booking.spot.excerpt"></p>
        </div>

        <!-- Equipement -->
        <div class="equipments">
          <h4>{{ $t("SPOTS.SPOT_EQUIPMENTS") }}</h4>
          <ul>
            <li v-for="eq in booking.spot.other_equipments" :key="eq.id">
              <span class="icon">
                <img :src="eq.image" alt="" />
              </span>
              <span class="text">
                {{ eq.name }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="booking-form-right">
      <div class="step-header">
        <el-steps :active="step" finish-status="success">
          <el-step title="Sélection"></el-step>
          <el-step title="Clients"></el-step>
        </el-steps>
      </div>
      <div v-if="step === 0" class="step-body step-1">
        <div class="buttons-group">
          <el-button class="continue" type="primary" @click="next">
            {{ $t("COMMON.CONTINUE") }}
          </el-button>
        </div>
      </div>
      <div v-if="step === 1" class="step-body step-2">
        <div class="buttons-group">
          <el-button class="previous" type="primary" @click="prev">
            {{ $t("COMMON.PREVIOUS") }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import {
  Button,
  Col,
  Header,
  Image,
  Row,
  Divider,
  Step,
  Steps,
} from "element-ui";
import { phoneTypesOptions } from "@/constants/common";
import formMixin from "@/mixins/form-mixin";
import { defaultAddressInformation } from "@/constants/defaultAddressInformation";
import "flatpickr/dist/flatpickr.css";

export default {
  layout: "DashboardLayout",

  components: {
    [Header.name]: Header,
    [Divider.name]: Divider,
    [Row.name]: Row,
    [Col.name]: Col,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Button.name]: Button,
    [Image.name]: Image,
  },

  mixins: [formMixin],

  props: [
    "bookingData",
    "formErrors",
    "loading",
    "hideOrganization",
    "hideSubmit",
  ],

  data() {
    let bookingData = { ...this.bookingData };
    bookingData = this.$fillUserOrganizationData(bookingData);
    return {
      booking: bookingData,
      phoneTypesOptions,
      bookings: [],
      total: 0,
      step: 0,
      showModal: false,
      defaultAddressInformation,
      showBookingCheckModal: false,
      processing: false,
      preview: bookingData.spot.picture,
    };
  },

  created() {},

  mounted() {},

  computed: {},

  methods: {
    async handleSubmit() {
      await this.sendInformation();
    },

    async sendInformation() {
      if (this.booking.lastname) {
        this.booking.lastname = this.booking.lastname.toString().toUpperCase();
      }
      if (this.booking.firstname) {
        this.booking.firstname = this.booking.firstname
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      }
      if (this.booking.company_name) {
        this.booking.company_name = this.booking.company_name
          .toString()
          .toUpperCase();
      }
      if (this.booking.address) {
        this.booking.address = this.booking.address.toString().toUpperCase();
      }
      if (this.booking.city) {
        this.booking.city = this.booking.city.toString().toUpperCase();
      }
      if (this.booking.zipcode) {
        this.booking.zipcode = this.booking.zipcode.toString().toUpperCase();
      }
      let bookingData = cloneDeep(this.booking);
      bookingData = this.$fillUserOrganizationData(bookingData);
      this.$emit("bookingSubmitted", bookingData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    async toggleModalState() {
      this.showModal = !this.showModal;
    },

    prev() {
      if (this.step++ > -1) this.step = 0;
    },

    next() {
      if (this.step++ > 2) this.step = 0;
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    bookingData: {
      handler: function (bookingData) {
        if (bookingData) {
          this.booking = {
            ...this.booking,
            ...cloneDeep(bookingData),
          };
          this.preview = this.booking.spot.picture;
        }
      },
      deep: true,
    },
  },
};
</script>
