export default {
  type: "bookings",
  status: null,
  start_at: null,
  end_at: null,
  adult_count: null,
  children_count: null,
  pet_count: null,
  special_request: null,
  comment: null,
  confirmation_expires_at: null,
  relationshipNames: [
    "organization",
    "spot",
    "client",
  ],
  organization: {
    type: "organizations",
    id: null,
  },
  spot: {
    type: 'spots',
    id: null,
  },
  client: {
    type: 'clients',
    id: null,
  },
};
