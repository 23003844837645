<template>
  <div class="container-fluid">
    <booking-form
      :loading="loading"
      :bookingData="booking"
      :formErrors="formErrors"
      @bookingSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultBooking from "../defaultBooking";
import BookingForm from "../partials/BookingForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    BookingForm,
  },

  mixins: [alertLeave],

  props: {
    spotId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      booking: cloneDeep(defaultBooking),
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.getSpot();
  },

  methods: {
    async getSpot() {
      this.loading = true;
      try {
        await this.$store.dispatch("spots/get", this.spotId);
        this.booking.spot = this.$store.getters["spots/spot"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.loading = false;
      }
    },
    async handleSubmit(booking) {
      this.loading = true;

      const bookingData = cloneDeep(booking);
      delete bookingData.id;

      try {
        await this.$store.dispatch("bookings/add", bookingData);
        this.$notify({
          type: "success",
          message: this.$t("BOOKINGS.BOOKING_ADDED"),
        });
        const booking = await this.$store.getters["bookings/booking"];
        this.$emit("onViewBooking", booking, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
